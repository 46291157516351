import { useEffect, useState } from "react";
import "./App.css";

function App() {
  const [token, setToken] = useState<any>(localStorage.getItem("token"));

  useEffect(() => {
    const stateToken = localStorage.getItem("token");
    if (stateToken) {
      setToken(stateToken);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    // Listen for Authentication requests from Fastcheckout App
    window.addEventListener("message", (event: any) => {
      // TODO-restrict only for known merchant domains 👇🏼
      // if (event.origin !== 'https://buckaroo.com') return;

      // send global token to the Fastcheckout App
      if (event.data.type === "GET_GLOBAL_TOKEN") {
        console.log("*** Global token request initiated ***");
        if (
          localStorage.getItem("token") &&
          localStorage.getItem("token") !== "null"
        ) {
          event.source?.postMessage(
            {
              type: "RECEIVE_GLOBAL_TOKEN",
              token: localStorage.getItem("token"),
            },
            event.origin
          );
          console.log("*** Global token shared successfully ***");
        } else {
          event.source?.postMessage(
            {
              type: "RECEIVE_GLOBAL_TOKEN",
              token: "",
            },
            event.origin
          );
          console.log("*** Global token is not available ***");
        }
      }

      // set global token from the Fastcheckout App
      if (event.data.type === "SET_GLOBAL_TOKEN") {
        if (event.data.token && event.data.token !== "") {
          setToken(event.data.token);
          localStorage.setItem("token", event.data.token);
          console.log("*** Global token changed ***");
        }
      }

      // revoke global token
      if (event.data.type === "REVOKE_GLOBAL_TOKEN") {
        setToken("null");
        localStorage.setItem("token", "null");
        console.log("*** Global token revoked ***");
      }
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <center>
          <h1>AdUp</h1>
          {token && token !== "null" ? (
            <h4>Successfully Signed In</h4>
          ) : (
            <h4>Signing In...</h4>
          )}
        </center>
      </header>
    </div>
  );
}

export default App;
